import React from 'react';
import Breadcrumbcustom from '../Common/Breadcrumbcustom';

function Profile(){
    return(
        <div class="container" >
        <Breadcrumbcustom pages={['Home', 'Profile']}/>

        </div>
    )
}
export default Profile;