import React from 'react';
import { useLocation } from 'react-router-dom'
import Breadcrumbcustom from '../Common/Breadcrumbcustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Grocerydetails() {
    const location = useLocation()
    const store = location.state
    console.log(store);
    return (
        <div class="container">
            <Breadcrumbcustom pages={['Home', 'Groceries']} />
            <div className='row w-100'>
                <div className='col-2'>
                    <img alt="" className='mt-3 me-3' style={{ maxWidth: "90%", maxHeight: "65%", borderRadius: "50%" }} src={store.storeprop.logo} />
                </div>
                <div className='col-10'>
                    <div className='row'>
                        <div className='col'>
                            <p className='fs-4'>{store.storeprop.name}</p>
                            <p className='fs-6'>{store.storeprop.categories}</p>
                            <div className='d-flex'>
                                <FontAwesomeIcon icon="fa-solid fa-star" color="#553CB2" />
                                <h6 className='ps-2'>{store.storeprop.rating}</h6>
                            </div>
                            <div className='d-flex my-4' >
                                {store.storeprop.tags.map((tag) => (
                                    <div className='me-3 p-2 fw-bold' style={{ backgroundColor: "#EEF2F7", borderRadius: "8px", color: "#553CB2" }}>
                                        <p class="card-text">{tag}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5'>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item fs-5" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Locations</button>
                    </li>
                    <li class="nav-item fs-5" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Products</button>
                    </li>
                    <li class="nav-item fs-5" role="presentation">
                        <button class="nav-link" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="false">Reviews</button>
                    </li>
                    <li class="nav-item fs-5" role="presentation">
                        <button class="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false">Other Details</button>
                    </li>
                </ul>
            </div>


            <div class="tab-content">
                <div class="tab-pane active mt-5" id="home" role="tabpanel" aria-labelledby="home-tab">
                    Locations
                </div>
                <div class="tab-pane mt-5" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    Products
                 </div>
                <div class="tab-pane mt-5" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                    Reviews
                </div>
                <div class="tab-pane mt-5" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                    Other Details
                </div>
            </div>

        </div>
    )
}
export default Grocerydetails;