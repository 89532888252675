import React from "react";
import Khazana from "../Assets/Images/Khazana.jpeg"
import Lazeez from "../Assets/Images/Lazeez.jpeg"
import Nafisa from "../Assets/Images/Nafisa.jpeg"
import BurgerFactory from "../Assets/Images/BurgerFactory.jpeg"

function Highestrated() {
    return (
        <div class="container d-flex w-100 justify-content-between overflow-auto my-5">
            <div class="me-2 custom-card mx-2" >
                <img style={{ height:"12rem"}} src={Khazana} class="" alt="Khazana" />
                <div class="p-2">
                    <h5 class="card-title">Khazana</h5>
                    <p class="card-text">An unforgettable experience with Indian cuisine.</p>
                </div>
            </div>
            <div class=" me-2 custom-card mx-2" >
                <img style={{ height:"12rem"}} src={Lazeez} class="" alt="Lazeez" />
                <div class="p-2">
                    <h5 class="card-title">Shawarma Town</h5>
                    <p class="card-text">Shawarma Lovers spot!</p>
                </div>
            </div>
            <div class=" me-2 custom-card mx-2">
                <img style={{ height:"12rem"}} src={Nafisa} class="" alt="Nafisa" />
                <div class="p-2">
                    <h5 class="card-title">Biryani Boyz</h5>
                    <p class="card-text">Best Biryani in town!</p>
                </div>
            </div>

            <div class=" me-2 custom-card mx-2" >
                <img style={{ height:"12rem"}} src={BurgerFactory} class="" alt="Burger Factory" />
                <div class="p-2">
                    <h5 class="card-title">Burger Factory</h5>
                    <p class="card-text">Grilled to perfection!</p>
                </div>
            </div>
        </div>
    );
}
export default Highestrated;